import React, { useCallback, useEffect, useState } from "react"
import { Button, message, Popconfirm, Table, Divider, Radio, Card, Input, DatePicker, Popover } from "antd"
import { useDeleteData, useGetData } from "../../hooks/serviceGetways"
import { PlusCircleFilled, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Link, useHistory } from 'react-router-dom';
import { IoAnalyticsOutline, IoLocationOutline, IoLockClosedOutline, IoLogoInstagram, IoMailOpenOutline, IoPeopleOutline, IoPhonePortraitOutline, IoPricetagOutline } from "react-icons/io5";
import _ from "lodash";
import moment from 'moment'
const { Column } = Table;

let UserList = () => {
    let history = useHistory()
    let [documents, setDocuments] = useState([])
    let [loadPage, setLoadPage] = useState(false)
    let [pageSize, setPageSize] = useState(100)
    let [queries, setQueries] = useState({ dateType: "halfYearly" })
    let [searchTerm, setSearchTerm] = useState('')
    let getData = useGetData()
    let deleteData = useDeleteData()

    // let getDocuments = useCallback(() => {
    //     getData("documents/list", { query: { ...pagination } }).then(({ result, resultMessage }) => {
    //         setDocuments(result.rows)
    //         setPagination({ page: result.page, pageSize: result.pageSize, total: result.total })
    //         setLoadPage(true)
    //     }).catch(err => {
    //         message.error(err.message)
    //     })
    // }, [])

    const dateTypeToDate = {
        monthly: moment().subtract(1, "month").startOf("day").toISOString(),
        quarterYearly: moment().subtract(3, "month").startOf("day").toISOString(),
        halfYearly: moment().subtract(6, "month").startOf("day").toISOString(),
        yearly: moment().subtract(1, "year").startOf("day").toISOString()
    }

    let getDocuments = useCallback((dateType, startDate, endDate) => {
        getData("documents/list", {
            query: {
                startDate: dateType === "custom" ? moment(startDate).toISOString() : dateTypeToDate[dateType],
                endDate: dateType === "custom" ? moment(endDate).toISOString() : moment().endOf("day").toISOString()
            }
        }).then(({ result, resultMessage }) => {
            setDocuments(result.rows)
            setLoadPage(true)
        }).catch(err => {
            message.error(err.message)
        })
    }, [])

    useEffect(() => {
        getDocuments(queries.dateType, queries.startDate, queries.endDate)
    }, [queries])


    // useEffect(() => {
    //     if (loadPage) {
    //         getData("documents/list", { query: { ...pagination } }).then(({ result, resultMessage }) => {
    //             setDocuments(result.rows)
    //         }).catch(err => {
    //             message.error(err.message)
    //         })
    //     }
    // }, [])

    let deleteDocument = (typeId) => {
        deleteData("documents/" + typeId, {}).then(({ result, resultMessage }) => {
            if (result)
                message.success("Döküman başarılı bir şekilde silindi.")
            getDocuments(queries.dateType)
        }).catch(err => {
            message.error(err.message)
        })
    }


    return <>
        {/* <div className="d-flex align-items-end justify-content-end mb-1 flex-column">
            <Button icon={<PlusCircleFilled />} onClick={() => history.push("./new-documents/new")}>Yeni Masak Ekle</Button>
        </div> */}
        <div className="d-none d-md-block d-lg-block d-xl-block">
            <Card className="p-0" bodyStyle={{ "padding": 10 }}>
                <Table title={() => <div className="d-flex align-items-center justify-content-between">
                    <Input style={{ width: 300 }} value={searchTerm} placeholder="Ara..." onChange={(e) => setSearchTerm(e.target.value)} />
                    <div className="d-flex position-relative">
                        <Radio.Group value={queries.dateType} onChange={e => setQueries({ ...queries, dateType: e.target.value })}>
                            <Radio.Button value="monthly">Aylık</Radio.Button>
                            <Radio.Button value="quarterYearly">3 Aylık</Radio.Button>
                            <Radio.Button value="halfYearly">6 Aylık</Radio.Button>
                            <Radio.Button value="yearly">12 Aylık</Radio.Button>
                            <Radio.Button value="custom" className="p-0"><Popover style={{ zIndex: 99, width: "100%", height: "100%" }} placement="bottomLeft" title={"Tarih Seçiniz"} content={
                                <DatePicker.RangePicker format="DD.MM.YYYY" onChange={(date) => setQueries({ dateType: "custom", startDate: moment(date[0]).toISOString(), endDate: moment(date[1].toISOString()) })} />
                            } trigger="click">
                                <span className="p-3">Özel</span>
                            </Popover>
                            </Radio.Button>
                        </Radio.Group>
                    </div>
                </div>}
                    dataSource={!searchTerm ? documents : _.chain(documents)
                        .filter((item) => _.chain(item)
                            .pickBy(_.isString)
                            .values()
                            .join('')
                            .toLower()
                            .deburr()
                            .includes(searchTerm.toLocaleLowerCase())
                            .value()
                        ).value()} rowKey="_id" scroll={{ x: 700 }} pagination={{ pageSize: pageSize }} onChange={(pagination) => setPageSize(pagination.pageSize)} columns={[
                            { dataIndex: "name", key: "name", title: "Ad Soyad", width: "200px", sorter: (a, b) => a.name.localeCompare(b.name) },
                            // { dataIndex: "identityNumber", key: "identityNumber", title: "T.C. Kimlik No", sorter: (a, b) => a.identityNumber.localeCompare(b.identityNumber), align: "center" },
                            { dataIndex: "phone", key: "phone", title: "Telefon", align: "center", sorter: (a, b) => a.phone.localeCompare(b.phone) },
                            { dataIndex: "address", key: "address", title: "Adres", align: "center", sorter: (a, b) => a.address.localeCompare(b.address) },
                            { dataIndex: "job", key: "job", title: "Meslek", align: "center", sorter: (a, b) => a.job.localeCompare(b.job) },
                            { dataIndex: "amount", key: "amount", title: "İşlem Tutarı", align: "center", sorter: (a, b) => a.amount - b.job },
                            { dataIndex: "createdAt", key: "createdAt", title: "İşlem Tarihi", align: "center", sorter: (a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf(), render: (val) => moment(val).format("DD.MM.YYYY HH:mm") },
                            {
                                dataIndex: "edit", key: "edit", title: "Düzenle", align: "center", width: "150px", render: (text, record) => (
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Link to={"/new-documents/" + record._id}>
                                            <Button icon={<EditOutlined />}>
                                            </Button>
                                        </Link>
                                        <Divider type="vertical" ></Divider>
                                        <Popconfirm
                                            onConfirm={() => deleteDocument(record._id)} title="Silmeyi Onaylıyor musunuz?"
                                            okText="Onayla" cancelText="Vazgeç">
                                            <Button type="danger" icon={<DeleteOutlined />}>
                                            </Button>
                                        </Popconfirm>
                                    </div>
                                )
                            }
                        ]}>
                </Table >
            </Card>
        </div >
        <div>
            {documents.map(document =>
                <Card key={document._id} className="d-xxl-none d-xl-none d-lg-none d-md-none d-sm-block mb-3">
                    <div className="d-table justify-content-start align-items-center">
                        <div className="d-block mb-2">
                            <IoPeopleOutline className="me-1" />
                            <span>{[document.name, document.surname].join(" ")}</span>
                        </div>


                        <div className="d-block mb-2">
                            <IoLockClosedOutline className="me-1" />
                            <span>{document.identityNumber}</span>
                        </div>

                        <div className="d-block mb-2">
                            <IoPhonePortraitOutline className="me-1" />
                            <span>{document.phone}</span>
                        </div>


                        <div className="d-block mb-2">
                            <IoLocationOutline className="me-1" />
                            <span>{document.address}</span>
                        </div>

                        <div className="d-block mb-2">
                            <IoAnalyticsOutline className="me-1" />
                            <span>{document.job}</span>
                        </div>

                        <div className="d-block mb-2">
                            <IoPricetagOutline className="me-1" />
                            <span>{document.amount}</span>
                        </div>

                        <div className="d-flex mb-2">
                            <Link to={"/new-documents/" + document._id}>
                                <Button icon={<EditOutlined />}>
                                    Düzenle
                                </Button>
                            </Link>
                            <div className="mx-2"></div>
                            <Popconfirm
                                onConfirm={() => deleteDocument(document._id)} title="Silmeyi Onaylıyor musunuz?"
                                okText="Onayla" cancelText="Vazgeç">
                                <Button type="danger" icon={<DeleteOutlined />}>
                                    Sil
                                </Button>
                            </Popconfirm>
                        </div>
                    </div>
                </Card>)}
        </div>
        {/* <div className="w-100 d-flex justify-content-end align-items-center mt-2">
            <Pagination showTotal={total => `Toplam : ${total} `}
                showSizeChanger
                current={pagination.page}
                total={pagination.total}
                pageSize={pagination.pageSize}
                onChange={(p, ps) => setPagination({ ...pagination, page: p, pageSize: ps })}
            />
        </div> */}
    </>

}

export default UserList