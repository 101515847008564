import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import menu from '../../configs/menuNavigations'
import LOGO from '../../assets/images/SAMLIOGLU.png'
import { ContextLayout } from '../../utility/LayoutContext'
import { Tooltip } from 'antd'

const Header = (props) => {
    let { user,setUser,setToken } = useContext(ContextLayout)
    let { open, setOpen } = props
    return (
        <div className="header-container w-100">
            {user && <div className="text-center" style={{ fontSize: 20, fontWeight: 500, marginTop: 10 }}>{[user.name,user.lastname].join(" ")}</div>}
            <div className='d-flex w-50 align-items-end justify-content-end flex-row'>
                <div className='sidebar'>
                    <ul className="w-100 d-flex mt-3">
                        {menu.map((item, i) =>
                            <li key={i}>
                                <Link to={`/${item.path}`} className="cursor-pointer d-flex w-100 align-items-center justify-content-start">
                                    <i className={item.icon}></i>
                                    <span className='tooltips'>{item.title}</span>
                                </Link>
                            </li>
                        )}
                        {user && user.role === "admin" &&
                            <li key={"3"}  style={{ maxWidth: 150 }}>
                                <Link to={`/users`} className="cursor-pointer d-flex w-100 align-items-center justify-content-start">
                                    <i className="bx bxs-user"></i>
                                    <span className='tooltips'>Kullancılar</span>
                                </Link>
                            </li>}
                            <li key={"4"} style={{ width: 50 }}>
                                <Link to={`/login`} className="cursor-pointer d-flex w-100 align-items-center justify-content-start" onClick={()=>{setToken(null);setUser(null);}}>
                                    <Tooltip title="Çıkış"><i style={{fontSize:28}} className="bx bxs-log-in-circle"></i></Tooltip>
                                </Link>
                            </li>
                    </ul>
                </div>
            </div>
        </div >
    )
}

export default Header
