import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useGetData, usePostData, usePutData, useUploadFile } from '../../hooks/serviceGetways'
import { Button, Form, Input, InputNumber, message, Modal, Select, Spin, Switch } from 'antd'
import SingleFileUpload from '../../components/SingleFileUpload'
import SingleImageUpload from '../../components/SingleImageUpload'
import CropImageUpload from '../../components/CropImageUpload'
import PhoneInput from '../../components/PhoneInput';
import { Label } from 'reactstrap';
import ShowFile from './showFile'
import { PrinterFilled } from '@ant-design/icons';
import ReactToPrint from "react-to-print";
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash'
import classnames from 'classnames'
import styles from './newDocument.module.css'
const { Option } = Select

const NewDocuments = () => {
    let postData = usePostData()
    let putData = usePutData()
    let getData = useGetData()
    let [upload, progress] = useUploadFile()
    let [loading, setLoading] = useState(false)
    let [record, setRecord] = useState({ currencies: '₺' })
    const { id } = useParams()
    const history = useHistory()
    const pritingRef = useRef()

    let validate = useCallback(() => {
        if (!record) return {};
        let errors = {};

        // if (record.name == null || record.name.length === 0)
        //     errors.name = 'Zorunlu Alan!'

        // if (record.surname == null || record.surname.length === 0)
        //     errors.surname = 'Zorunlu Alan!'

        // if (record.phone == null || record.phone.length === 0)
        //     errors.phone = 'Zorunlu Alan!'

        if (record.identityNumber == null || record.identityNumber.length !== 11 || !/^[1-9]{1}[0-9]{9}[02468]{1}$/.test(record.identityNumber))
            errors.identityNumber = 'Zorunlu Alan!'

        // if (record.address == null || record.address.length === 0)
        //     errors.address = 'Zorunlu Alan!'

        // if (record.job == null || record.job.length === 0)
        //     errors.job = 'Zorunlu Alan!'

        if (record.amount == null || record.amount.length === 0)
            errors.amount = 'Zorunlu Alan!'

        if (record.IDCardFront == null)
            errors.IDCardFront = 'Zorunlu Alan!'

        if (record.IDCardBack == null)
            errors.IDCardBack = 'Zorunlu Alan!'


        errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
        errors.hasError = errors.all.length > 0;
        return errors;
    }, [record]);

    let [errors, setErrors] = useState({})

    let sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }
    const getDocument = useCallback(async (_id) => {
        setLoading(true)
         await sleep(500)
        getData("documents/find/" + _id || id, {}).then(({ result, resultMessage }) => {
            if (result) {
                setRecord(result)
                setLoading(false)
            }
            else
                history.push("/documents")
        })
    }, [])

    useEffect(() => {
        if (id && id !== "new")
            getDocument(id)
        else if (id === "new")
            setRecord({ currencies: '₺' })
    }, [id])

    useEffect(() => { setErrors(validate()) }, [record, validate]);

    function dataURLtoFile(data, filename) {
        let dataurl = data.url
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }


    let uploadMedia = async (type) => {
        if (!["front", "back", "signature"].includes(type)) {
            message.error("Resim yüklenirken sorun oluştu.")
            return false
        }
        let { result, resultMessage } = await upload({
            files: [type === "front" ? dataURLtoFile(record.IDCardFront)
                : type === "signature" ? dataURLtoFile(record.signatureDocument) :
                    dataURLtoFile(record.IDCardBack)]
        })
        if (result)
            return result[0]
        else
            message.error(resultMessage)
    }

    let save = async () => {
        let IDCardFront = record.IDCardFront && record.IDCardFront._id === "new" ? await uploadMedia("front") : {
            duration: record.IDCardFront.duration,
            height: record.IDCardFront.height,
            mimeType: record.IDCardFront.mimeType,
            thumb: record.IDCardFront.thumb,
            type: record.IDCardFront.type,
            url: record.IDCardFront.url,
            width: record.IDCardFront.width,
            _id: record.IDCardFront._id
        }
        let IDCardBack = record.IDCardBack && record.IDCardBack._id === "new" ? await uploadMedia("back") : {
            duration: record.IDCardBack.duration,
            height: record.IDCardBack.height,
            mimeType: record.IDCardBack.mimeType,
            thumb: record.IDCardBack.thumb,
            type: record.IDCardBack.type,
            url: record.IDCardBack.url,
            width: record.IDCardBack.width,
            _id: record.IDCardBack._id
        }
        let signatureDocument = record.signatureDocument && record.signatureDocument.uid ? await uploadMedia("signature") : record.signatureDocument

        if (record._id)
            putData("documents", { ...record, IDCardFront: IDCardFront, IDCardBack: IDCardBack, signatureDocument: signatureDocument }).then(({ result, resultMessage }) => {
                if (result)
                    message.success("Kayıt başarılı bir şekilde güncellendi.")
                history.push(`./${record._id}`)
                window.location.reload()
            })
        else
            postData("documents", { ...record, IDCardFront: IDCardFront, IDCardBack: IDCardBack }).then(({ result, resultMessage }) => {
                if (result) {
                    message.success("Kayıt başarılı bir şekilde eklendi.")
                    history.push(`./${result._id}`)
                    window.location.reload()
                }
            })


    }
    if (loading)
        return (<div style={{ width: '100vw', height: '100vh' }} className="d-flex align-items-center justify-content-center"><Spin size='large'></Spin></div>)
    return (<div className='row d-flex ps-2'>
        <div className={classnames("col-lg-6 col-md-12", styles.editorContainer)}>
            <div className='w-100 d-flex justify-content-end align-items-center'>

                <Button className='m-2 me-0' key="1" type="default" onClick={() => history.push("/documents")}>
                    Vazgeç
                </Button>

                <Button className='ms-2' key="2" type="primary" onClick={save}
                    disabled={errors.hasError}>
                    {record && !record._id ? "Kaydet" : "Düzenle ve Kaydet"}
                </Button>
                <ReactToPrint
                    bodyClass=''
                    documentTitle={[_.replace(record.name, " ", "_"), _.replace(record.surname, " ", "_")].join("_")}
                    trigger={() => <Button className='ms-2' disabled={errors.hasError || !record._id} key="3" icon={<PrinterFilled />} type="primary" onClick={save}>Yazdır</Button>}
                    content={() => pritingRef.current}
                />


            </div>

            <Form labelCol={{ xs: 4 }} wrapperCol={{ xs: 20 }} labelAlign="left">
                <Form.Item key="1" label='Ad Soyad' help={errors ? errors.name : ""} validateStatus={errors && errors.name ? 'error' : 'success'}>
                    <Input value={record?.name}
                        required={true}
                        onChange={e => setRecord({ ...record, name: e.target.value })} />
                </Form.Item>

                {/* <Form.Item key="2" label='Soyadı' help={errors ? errors.surname : ""} validateStatus={errors && errors.surname ? 'error' : 'success'}>
                    <Input value={record?.surname}
                        required={true}
                        onChange={e => setRecord({ ...record, surname: e.target.value })} />
                </Form.Item> */}

                <Form.Item key="10" label='T.C. No' help={errors ? errors.identityNumber : ""} validateStatus={errors && errors.identityNumber ? 'error' : 'success'}>
                    <Input
                        maxLength={11}
                        minLength={11}
                        mask={""}
                        value={record?.identityNumber}
                        onChange={e => setRecord({ ...record, identityNumber: e.target.value })}
                        placeholder="___________"
                    />
                </Form.Item>

                <Form.Item key="3" label='Cep No' help={errors ? errors.phone : ""} validateStatus={errors && errors.phone ? 'error' : 'success'}>
                    <PhoneInput
                        value={record?.phone || ""}
                        onChange={e => setRecord({ ...record, phone: e.target.value.replace("+90") })}
                        mask="(999) 999-99-99"
                        placeholder="(___) ___-__-__"
                        addonBefore={'+90'}
                    />
                </Form.Item>

                <Form.Item key="4" label='Adres' help={errors ? errors.address : ""} validateStatus={errors && errors.address ? 'error' : 'success'}>
                    <Input value={record?.address}
                        required={true}
                        onChange={e => setRecord({ ...record, address: e.target.value })} />
                </Form.Item>

                <Form.Item key="8" label='Meslek' help={errors ? errors.job : ""} validateStatus={errors && errors.job ? 'error' : 'success'}>
                    <Input value={record?.job}
                        required={true}
                        onChange={e => setRecord({ ...record, job: e.target.value })} />
                </Form.Item>

                <Form.Item key="5" label='Tutar' help={errors ? errors.amount : ""} validateStatus={errors && errors.amount ? 'error' : 'success'}>
                    <InputNumber style={{ width: '60%' }} value={record?.amount} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        required={true}
                        addonAfter={<Select style={{ width: 60 }} value={record?.currencies || "₺"} onSelect={(v) => setRecord({ ...record, currencies: v })}>
                            <Option key={"₺"} value={"₺"}>₺</Option>
                            <Option key={"$"} value={"$"}>$</Option>
                            <Option key={"€"} value={"€"}>€</Option>
                            <Option key={"gold"} value={"gold"}>Altın</Option>
                        </Select>}
                        onChange={v => setRecord({ ...record, amount: v })} />
                </Form.Item>
                <div className={classnames('col-lg-6 col-md-12 w-100', styles.uploadContainer)}>
                    <Form.Item className='w-33' key="6" label='' help={errors ? errors.IDCardFront : ""} validateStatus={errors && errors.IDCardFront ? 'error' : 'success'}>
                        <Label className={classnames("me-3", styles.label)}> Kimlik Ön Yüz</Label>
                        <CropImageUpload record={record} setRecord={setRecord} name="IDCardFront" />
                    </Form.Item>

                    <Form.Item className='w-33' key="7" label='' help={errors ? errors.IDCardBack : ""} validateStatus={errors && errors.IDCardBack ? 'error' : 'success'}>
                        <Label className={classnames("me-3", styles.label)}> Kimlik Arka Yüz</Label>
                        <CropImageUpload record={record} setRecord={setRecord} name="IDCardBack" />
                    </Form.Item>

                    <Form.Item className='w-33' key="15" label=''>
                        <Label className={classnames("me-3", styles.label)}> İmzalı Döküman</Label>
                        <CropImageUpload record={record} setRecord={setRecord} name="signatureDocument" />
                    </Form.Item>
                </div>
            </Form>
        </div>
        <div className={classnames('col-lg-6 col-md-12', styles.mobileDoc)}>
            <ShowFile record={record} ref={pritingRef}></ShowFile>
        </div>
    </div>
    )
}

export default NewDocuments