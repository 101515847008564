import { PlusOutlined } from '@ant-design/icons'
import { Modal, Slider, Upload } from 'antd'
import React, { useState, useRef, useEffect } from 'react'

import ReactCrop, {
    centerCrop,
    makeAspectCrop
} from 'react-image-crop'
import { canvasPreview } from './canvasPreview'


function centerAspectCrop(
    mediaWidth,
    mediaHeight,
    aspect,
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 350,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}

export default (props) => {
    let { record, setRecord, name } = props
    const [imgSrc, setImgSrc] = useState(null)
    const previewCanvasRef = useRef(null)
    const imgRef = useRef(null)
    const [crop, setCrop] = useState()
    const [completedCrop, setCompletedCrop] = useState()
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)



    function onSelectFile({ file }) {
        if (file) {
            setCrop(undefined) // Makes crop preview update between images.
            const reader = new FileReader()
            reader.addEventListener('load', () => {
                setImgSrc(reader.result?.toString() || '')
            },
            )
            reader.readAsDataURL(file.originFileObj)
        }
    }

    function onImageLoad(e) {
        const { width, height } = e.currentTarget
        setCrop(centerAspectCrop(width, height, 349 / 220))

    }

    useEffect(() => {
        if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
            canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate)
        }
    }, [completedCrop, scale, rotate])


    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    return (
        <div>
            <div className="Crop-Controls">
                <Upload
                    fileList={!record[name] ? [] : [record[name]]}
                    multiple={false}
                    customRequest={dummyRequest}
                    listType="picture-card"
                    onChange={onSelectFile}
                >
                    {record[name] ? null : <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Yükle</div>
                    </div>}
                </Upload>

                <Modal style={{minWidth:'max-content'}} open={imgSrc} onCancel={() => setImgSrc(null)} onOk={() => {
                    console.log({first:previewCanvasRef.current})
                    setRecord({
                        ...record, [name]: {
                            _id: "new",
                            uid: "new",
                            name: "Dosya",
                            status: 'done',
                            url: previewCanvasRef.current.toDataURL()
                        }
                    }); setImgSrc(null);
                }}>

                    <div className='d-flex flex-column'>
                        <div className='m-2 mb-4'>
                            {!!imgSrc && (
                                <ReactCrop
                                    crop={crop}
                                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                                    onComplete={(c) => setCompletedCrop(c)}
                                >
                                    <img
                                        ref={imgRef}
                                        alt="Crop me"
                                        src={imgSrc}
                                        style={{
                                            transform: `scale(${scale}) rotate(${rotate}deg)`,
                                            borderRadius: 8,
                                            border: '1px solid lightgray',
                                            maxHeight: '70vh',
                                            minHeight:800
                                        }}
                                        onLoad={onImageLoad}
                                    />
                                </ReactCrop>
                            )}
                        </div>
                        <div className='d-none m-2'>
                            {!!completedCrop && (
                                <canvas
                                    ref={previewCanvasRef}
                                    style={{
                                        borderRadius: 8,
                                        border: '1px solid lightgray',
                                        objectFit: 'contain',
                                        width: completedCrop.width,
                                        height: completedCrop.height,
                                    }}
                                />
                            )}
                        </div>

                    </div>
                    <div>
                        <label htmlFor="rotate-input">Scale: {scale || ""}</label>
                        <Slider
                            min={0}
                            max={3}
                            defaultValue={1}
                            value={scale}
                            onChange={(value) => setScale(Number(value))}
                            step={0.01}
                        />
                    </div>
                    <div>
                        <label htmlFor="rotate-input">Rotate: {rotate || 0} °</label>
                        <Slider
                            marks={{ 0: '0°', 45: '45°', 90: '90°', 135: '135°', 180: '180°' }}
                            min={0}
                            max={180}
                            value={rotate}
                            defaultValue={0}
                            onChange={(value) => setRotate(Math.min(180, Math.max(-180, Number(value))))}
                            step={1}
                        />
                    </div>
                </Modal>
            </div>
        </div>
    )
}
