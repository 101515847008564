
let config = [
    {
        id: 2,
        path: "documents",
        title: "Döküman Listesi",
        icon: "bx bxs-grid-alt",
        role: ["admin"]
    },
    {
        id: 3,
        path: "new-documents/new",
        title: "Yeni Masak Girişi",
        icon: "bx bxs-plus-square",
        role: ["admin"]
    }
    // {
    //     id: 1,
    //     path: "",
    //     title: "Anasayfa",
    //     icon: "bx bxs-grid-alt",
    //     role: ["admin"]
    // },
    // {
    //     id: 2,
    //     path: "currencies",
    //     title: "Birimler",
    //     icon: "bx bx-cube",
    //     role: ["admin"]
    // },
    // {
    //     id: 3,
    //     path: "board",
    //     title: "Panolar",
    //     icon: "bx bx-chalkboard",
    //     role: ["admin"]
    // },
    // {
    //     id: 5,
    //     path: "notifications",
    //     title: "Notifications",
    //     icon: "bx bxs-bell-ring",
    //     role: ["admin"]
    // },
    // {
    //     id: 3,
    //     path: "users",
    //     title: "Kullanıcılar",
    //     icon: "bx bx-user",
    //     role: ["admin"]
    // },
    // {
    //     id: 6,
    //     path: "galery",
    //     title: "Galeri",
    //     icon: "bx bx-image-alt",
    //     role: ["admin"]
    // },

    // {
    //     id: 7,
    //     path: "footerButton",
    //     title: "Footer Butonlar",
    //     icon: "bx bx-dock-bottom",
    //     role: ["admin"]
    // },
    // {
    //     id: 8,
    //     path: "settings",
    //     title: "Ayarlar",
    //     icon: "bx bxs-cog",
    //     role: ["admin"]
    // },
    // {
    //     id: 1,
    //     path: "companyPosts",
    //     title: "Paylaşımlar",
    //     icon: <IoHomeOutline />,
    //     role: ["manager"]
    // },
    // {
    //     id: 12,
    //     path: "codeList",
    //     title: "Tanımlı Kodlar",
    //     icon: <IoBarcodeOutline />,
    //     role: ["manager"]
    // },
    // {
    //     id: 2,
    //     path: "posts",
    //     title: "Postlar",
    //     icon: <IoShareOutline />,
    //     role: ["admin"]
    // },
    // {
    //     id: 21,
    //     path: "sharedPosts",
    //     title: "Paylaşımlarım",
    //     icon: <FaSlideshare />,
    //     role: ["admin", "user"]
    // },
    // {
    //     id: 3,
    //     path: "users",
    //     title: "Kullanıcılar",
    //     icon: <IoPersonOutline />,
    //     role: ["admin"]
    // },
    // {
    //     id: 4,
    //     path: "companies",
    //     title: "Firmalar",
    //     icon: <IoBusinessOutline />,
    //     role: ["admin"]
    // },
    // {
    //     id: 5,
    //     path: "priceTypes",
    //     title: "Puan Türleri",
    //     icon: <IoPricetagOutline />,
    //     role: ["admin"]
    // },
    // {
    //     id: 6,
    //     path: "settings",
    //     title: "Ayarlar",
    //     icon: <IoSettingsOutline />,
    //     role: ["admin", "user", "manager"]
    // }
]

export default config