import React, { lazy, Suspense, useState, useEffect } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom"
import { ContextLayout } from "./utility/LayoutContext";
import Cookies from "js-cookie";
import DashboardLayoutRoute from "./routers/DashboardLayoutRoute";
import FullLayoutRoute from "./routers/FullLayoutRoute";
import './App.css';
import DocumentList from "./pages/Documents/index"
import { API_URL } from "./env";
import NewDocuments from "./pages/Documents/newDocuments";
import Users from "./pages/Users/List";
import UserView from "./pages/Users/View"
import 'react-image-crop/dist/ReactCrop.css'

const App = () => {
  let [user, setUser] = useState(null)
  let [token, setToken] = useState(Cookies.get("token"))
  let [loading, setLoading] = useState(true)
  let [settings, setSettings] = useState(null)
  const [liveDatas, setLiveDatas] = useState([])

  return (
    <ContextLayout.Provider value={{ liveDatas, user, setUser, token, setToken, loading, setLoading, settings, setSettings }}>
      <BrowserRouter>
        <Switch>
          {/* <Route exact path="/"><Redirect to="/login" /></Route> */}
          <DashboardLayoutRoute exact path="/" component={DocumentList} />
          <DashboardLayoutRoute exact path="/new-documents/:id" component={NewDocuments} />
          <DashboardLayoutRoute exact path="/users" component={Users} />
          <DashboardLayoutRoute exact path="/users/:id" component={UserView} />
          <DashboardLayoutRoute exact path="*" component={DocumentList} />
        </Switch>
      </BrowserRouter>
    </ContextLayout.Provider>
  );
}

export default App;
