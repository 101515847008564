import React, { Component } from 'react'
import styles from './newDocument.module.css'

export default class showFile extends Component {
    render() {
        const { record } = this.props;

        return (
            <div className={styles.paperContainer}>
                <div className={styles.IDCardImageContainer}>
                    <div>{record.IDCardFront && <img className={styles.idCardImages} src={record.IDCardFront.url} alt="image"></img>}</div>
                    <div>{record.IDCardBack && <img className={styles.idCardImages} src={record.IDCardBack.url} alt="image"></img>}</div>
                </div>
                <div className={styles.descContainer}>
                    <div className='d-flex w-100 text-center'>
                        <span className={styles.header}>MALİ SUÇLAR ARAŞTIRMA KURULUNCA</span>
                    </div>
                    <span>Müşterinin tanınması</span>
                    <p><b className={styles.largeFont}>Madde 3 –</b> <b>(1)</b> Yükümlüler, müşterinin tanınmasına ilişkin esaslar kapsamında; Kendileri nezdinde yapılan veya aracılık ettikleri işlemlerde işlem yapılmadan önce, işlem yapanlar ile nam veya hesaplarına işlem yapılanların kimliklerini tespit etmek ve gerekli diğer tedbirleri almak zorundadır.
                    </p>
                    <p><span><b>(2)</b></span> Kimlik tespitinde esas belge nevilerini belirlemeye Bakanlık yetkili olup, kimlik tespitini gerektiren işlem türleri , bunların parasal sınırları ile müşterinin tanınmasına ilişkin ve konuyla ilgili diğer usul ve esaslar yönetmelikle belirlenir.</p>
                    {/* <p><b>AD SOYAD:</b> {[record?.name, record?.surname].join(" ")}</p> */}
                    <p><b>CEP TELEFONU :</b>{record?.phone}</p>
                    {/* <p><b>T.C KİMLİK NO: </b>{record?.identityNumber}</p> */}
                    <p><b>AÇIK ADRES :</b>{record?.address}</p><br />
                    <p><b>İMZA ÖRNEĞİ :</b></p><br />
                    <p><b>MESLEK :</b>{record?.job}</p>
                    <p><b>İŞLEM TUTARI :</b>{record?.amount} {record?.currencies || '₺'}</p>
                </div>
                <div className='w-100 text-end'>
                    <p><b>ŞAMLIOĞLU KUYUMCULUK SAN.VE TİC.A.Ş</b></p>
                    <p className='pe-4'><b>HOCAPAŞA V.D. : 7980631039</b></p>
                </div>
            </div>
        )
    }
}
